var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// https://d3js.org/d3-collection/ v1.0.7 Copyright 2018 Mike Bostock
(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  'use strict';

  var prefix = "$";

  function Map() {}

  Map.prototype = map.prototype = {
    constructor: Map,
    has: function (key) {
      return prefix + key in (this || _global);
    },
    get: function (key) {
      return (this || _global)[prefix + key];
    },
    set: function (key, value) {
      (this || _global)[prefix + key] = value;
      return this || _global;
    },
    remove: function (key) {
      var property = prefix + key;
      return property in (this || _global) && delete (this || _global)[property];
    },
    clear: function () {
      for (var property in this || _global) if (property[0] === prefix) delete (this || _global)[property];
    },
    keys: function () {
      var keys = [];

      for (var property in this || _global) if (property[0] === prefix) keys.push(property.slice(1));

      return keys;
    },
    values: function () {
      var values = [];

      for (var property in this || _global) if (property[0] === prefix) values.push((this || _global)[property]);

      return values;
    },
    entries: function () {
      var entries = [];

      for (var property in this || _global) if (property[0] === prefix) entries.push({
        key: property.slice(1),
        value: (this || _global)[property]
      });

      return entries;
    },
    size: function () {
      var size = 0;

      for (var property in this || _global) if (property[0] === prefix) ++size;

      return size;
    },
    empty: function () {
      for (var property in this || _global) if (property[0] === prefix) return false;

      return true;
    },
    each: function (f) {
      for (var property in this || _global) if (property[0] === prefix) f((this || _global)[property], property.slice(1), this || _global);
    }
  };

  function map(object, f) {
    var map = new Map(); // Copy constructor.

    if (object instanceof Map) object.each(function (value, key) {
      map.set(key, value);
    }); // Index array by numeric index or specified key function.
    else if (Array.isArray(object)) {
        var i = -1,
            n = object.length,
            o;
        if (f == null) while (++i < n) map.set(i, object[i]);else while (++i < n) map.set(f(o = object[i], i, object), o);
      } // Convert object to map.
      else if (object) for (var key in object) map.set(key, object[key]);
    return map;
  }

  function nest() {
    var keys = [],
        sortKeys = [],
        sortValues,
        rollup,
        nest;

    function apply(array, depth, createResult, setResult) {
      if (depth >= keys.length) {
        if (sortValues != null) array.sort(sortValues);
        return rollup != null ? rollup(array) : array;
      }

      var i = -1,
          n = array.length,
          key = keys[depth++],
          keyValue,
          value,
          valuesByKey = map(),
          values,
          result = createResult();

      while (++i < n) {
        if (values = valuesByKey.get(keyValue = key(value = array[i]) + "")) {
          values.push(value);
        } else {
          valuesByKey.set(keyValue, [value]);
        }
      }

      valuesByKey.each(function (values, key) {
        setResult(result, key, apply(values, depth, createResult, setResult));
      });
      return result;
    }

    function entries(map$$1, depth) {
      if (++depth > keys.length) return map$$1;
      var array,
          sortKey = sortKeys[depth - 1];
      if (rollup != null && depth >= keys.length) array = map$$1.entries();else array = [], map$$1.each(function (v, k) {
        array.push({
          key: k,
          values: entries(v, depth)
        });
      });
      return sortKey != null ? array.sort(function (a, b) {
        return sortKey(a.key, b.key);
      }) : array;
    }

    return nest = {
      object: function (array) {
        return apply(array, 0, createObject, setObject);
      },
      map: function (array) {
        return apply(array, 0, createMap, setMap);
      },
      entries: function (array) {
        return entries(apply(array, 0, createMap, setMap), 0);
      },
      key: function (d) {
        keys.push(d);
        return nest;
      },
      sortKeys: function (order) {
        sortKeys[keys.length - 1] = order;
        return nest;
      },
      sortValues: function (order) {
        sortValues = order;
        return nest;
      },
      rollup: function (f) {
        rollup = f;
        return nest;
      }
    };
  }

  function createObject() {
    return {};
  }

  function setObject(object, key, value) {
    object[key] = value;
  }

  function createMap() {
    return map();
  }

  function setMap(map$$1, key, value) {
    map$$1.set(key, value);
  }

  function Set() {}

  var proto = map.prototype;
  Set.prototype = set.prototype = {
    constructor: Set,
    has: proto.has,
    add: function (value) {
      value += "";
      (this || _global)[prefix + value] = value;
      return this || _global;
    },
    remove: proto.remove,
    clear: proto.clear,
    values: proto.keys,
    size: proto.size,
    empty: proto.empty,
    each: proto.each
  };

  function set(object, f) {
    var set = new Set(); // Copy constructor.

    if (object instanceof Set) object.each(function (value) {
      set.add(value);
    }); // Otherwise, assume it’s an array.
    else if (object) {
        var i = -1,
            n = object.length;
        if (f == null) while (++i < n) set.add(object[i]);else while (++i < n) set.add(f(object[i], i, object));
      }
    return set;
  }

  function keys(map) {
    var keys = [];

    for (var key in map) keys.push(key);

    return keys;
  }

  function values(map) {
    var values = [];

    for (var key in map) values.push(map[key]);

    return values;
  }

  function entries(map) {
    var entries = [];

    for (var key in map) entries.push({
      key: key,
      value: map[key]
    });

    return entries;
  }

  exports.nest = nest;
  exports.set = set;
  exports.map = map;
  exports.keys = keys;
  exports.values = values;
  exports.entries = entries;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const nest = exports.nest,
      set = exports.set,
      map = exports.map,
      keys = exports.keys,
      values = exports.values,
      entries = exports.entries,
      __esModule = exports.__esModule;